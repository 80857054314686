import React from 'react';
import { RouteComponentProps } from 'react-router';
import styles from './ImportValues.module.scss';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { useDeviceContext } from '../Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useMachineInfoContext } from '../Unitlookup/stores/MachineInfo/MachineInfoProvider';
import { getUserInfo } from '../Login/AuthenticationService';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { AlertDialog } from '../Unitlookup/components/AlertDialog';
import {
  ImportedValues,
  ImportedValuesService
} from '../Unitlookup/stores/ImportedValues/ImportedValuesService';
import {
  Brand,
  Category,
  MachineInfoApi
} from '../Unitlookup/stores/MachineInfo/MachineInfoApi';
import { UpdateGroupApi, Group } from '../Unitlookup/stores/Group/GroupApi';
import { useGroupContext } from '../Unitlookup/stores/Group/GroupProvider';
import { withOktaAuth } from '@okta/okta-react';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";

interface ImportValuesProps {
  serialNumber: string;
  name?: string;
  engineHours?: string;
  group?: string;
  brand?: string;
  model?: string;
  category?: string;
  productionDate?: string;
  vin?: string;
}

type Props = RouteComponentProps<ImportValuesProps> & IOktaContext;

const ImportValues: React.FC<Props> = props => {
  const deviceContext = useDeviceContext();
  const machineInfoContext = useMachineInfoContext();

  const groupContext = useGroupContext();

  const [importedValues, setImportedValues] = React.useState<
    ImportedValues | undefined
  >();
  const [initialLoading, setInitialLoading] = React.useState(false);
  const [initialLoadingFailed, setInitialLoadingFailed] = React.useState(false);
  const [importFailed, setImportFailed] = React.useState(false);
  const [importingValues, setImportingValues] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [invalidSerialNumber, setInvalidSerialNumber] = React.useState(false);
  //const [userInfo, setUserInfo] = React.useState();
  const [customerId, setCustomerId] = React.useState()
  const [userId, setUserId] = React.useState()

  React.useEffect(() => {
    if( props.authState?.accessToken)
    {
      let userInfo = getUserInfo(props.authState.accessToken);
      setCustomerId(userInfo?.customerId)
      setUserId(userInfo?.userId)
      //setUserInfo(getUserInfo(props.authState.accessToken));
    }
  }, [props.authState?.accessToken])

  const parseSearchParams = React.useCallback(
    (
      searchParam: string,
      brands: Brand[],
      categories: Category[],
      groups: Group[]
    ) => {
      const brandNames = brands.map(b => b.name);
      const categoryNames = categories.map(c => c.name);
      const groupNames = groups.map(c => c.name);
      return ImportedValuesService.parseSearchParams(
        searchParam,
        brandNames,
        categoryNames,
        groupNames
      );
    },
    []
  );

  const initialize = React.useCallback(
    async (searchParam: string): Promise<ImportedValues> => {
      let serialNumber: string;
      try {
        serialNumber = await ImportedValuesService.getSerialNumber(
          props.authState?.accessToken,
          searchParam
        );
      } catch (e) {
        setInvalidSerialNumber(true);
        return Promise.reject();
      }

      try {
        const [brands, categories, groups] = await deviceContext
          .load(serialNumber)
          .then(loadingResult =>
            Promise.all([
              MachineInfoApi.getBrands(props.authState?.accessToken),
              MachineInfoApi.getCategories(props.authState?.accessToken),
              UpdateGroupApi.getGroups(
                props.authState?.accessToken,
                loadingResult.id
              ),
              groupContext.load(loadingResult.id),
              machineInfoContext.load(serialNumber)
            ])
          );

        return Promise.resolve(
          parseSearchParams(searchParam, brands, categories, groups)
        );
      } catch (e) {
        return Promise.reject();
      }
    },
    [
      parseSearchParams,
      customerId,
      userId,
      deviceContext,
      machineInfoContext,
      groupContext,
      props.authState?.accessToken
    ]
  );

  const updateProviders = React.useCallback(() => {
    if (importedValues && importedValues.validation.allValid) {
      var unitValues = {};
      if (importedValues.name) {
        unitValues = { ...unitValues, name: importedValues.name };
      }
      if (importedValues.engineHours) {
        unitValues = {
          ...unitValues,
          totalRun1: Number.parseInt(importedValues.engineHours) * 3600
        };
      }
      deviceContext.setUnitInfoValues(unitValues);

      if (importedValues.group) {
        groupContext.onGroupSelectedByName(importedValues.group, true);
      }

      machineInfoContext.setMachineInfo({
        brand: importedValues.brand
          ? importedValues.brand
          : machineInfoContext.brand,
        model: importedValues.model
          ? importedValues.model
          : machineInfoContext.model,
        category: importedValues.category
          ? importedValues.category
          : machineInfoContext.category,
        productionDate: importedValues.productionDate
          ? importedValues.productionDate
          : machineInfoContext.productionDate,
        vin: importedValues.vin ? importedValues.vin : machineInfoContext.vin,
        extendedInformation: machineInfoContext.extendedInformation
      });
    }
  }, [importedValues, deviceContext, machineInfoContext, groupContext]);

  // Loading
  React.useEffect(() => {
    if( props.authState?.accessToken && userId && customerId) {
      if (!initialLoading && !initialLoadingFailed && !importedValues) {
        setInitialLoading(true);

        initialize(props.location.search)
          .then(parsedValues => {
            setImportedValues(parsedValues);
          })
          .catch(() => {
            setInitialLoadingFailed(true);
          })
          .finally(() => {
            setInitialLoading(false);
          });
      }
    }
  }, [
    props.location.search,
    initialize,
    userId,
    customerId,
    initialLoading,
    initialLoadingFailed,
    importedValues,
    updateProviders,
    props.authState?.accessToken
  ]);

  // Saving
  React.useEffect(() => {
    if (importingValues && !saving && importedValues) {
      setSaving(true);
      Promise.all([
        deviceContext.save(),
        machineInfoContext.save(
          importedValues.serialNumber!,
          customerId,
          userId,
          false
        ),
        groupContext.save(deviceContext.unitInfo.id)
      ])
        .then(() => props.history.push(`/${importedValues.serialNumber}`))
        .catch(() => {
          setImportFailed(true);
          setImportingValues(false);
          setSaving(false);
        });
    }
  }, [
    importingValues,
    saving,
    importedValues,
    deviceContext,
    machineInfoContext,
    groupContext,
    customerId,
    userId,
    props.history
  ]);

  const onImport = () => {
    if (
      importedValues &&
      importedValues.validation.allValid &&
      !importingValues
    ) {
      updateProviders();
      setImportingValues(true);
    }
  };

  const renderImportedValueItem = (
    key: string,
    label: string,
    value: string,
    valid: boolean = true
  ) => {
    return (
      <div className={styles.importValue}>
        {valid ? (
          <CheckCircleIcon className={styles.successIcon} />
        ) : (
          <ErrorIcon className={styles.errorIcon} />
        )}
        <TextField
          key={key}
          name={label}
          label={label}
          fullWidth
          variant="outlined"
          value={value || ''}
          margin="normal"
          disabled={true}
        />
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <h2>Import values</h2>
      <hr />

      {initialLoading && (
        <div className={styles.loadingSpinner}>
          <CircularProgress />
        </div>
      )}

      {initialLoadingFailed && (
        <div className={styles.failedToLoadInfo}>
          <ErrorIcon color="primary" />
          <span>
            {invalidSerialNumber ? 'Invalid serial number' : 'Failed to load'}
          </span>
        </div>
      )}

      {!initialLoading && importedValues && (
        <>
          {importedValues.serialNumber &&
            renderImportedValueItem(
              'serialNumber',
              'Serial number',
              importedValues.serialNumber
            )}
          {importedValues.name &&
            renderImportedValueItem('name', 'Name', importedValues.name)}
          {importedValues.engineHours &&
            renderImportedValueItem(
              'engineHours',
              'Operating hours',
              importedValues.engineHours.toString(),
              importedValues.validation.engineHoursValid
            )}
          {importedValues.group &&
            renderImportedValueItem(
              'group',
              'Group',
              importedValues.group,
              importedValues.validation.groupValid
            )}
          {importedValues.brand &&
            renderImportedValueItem(
              'brand',
              'Brand',
              importedValues.brand,
              importedValues.validation.brandValid
            )}
          {importedValues.model &&
            renderImportedValueItem('model', 'Model', importedValues.model)}
          {importedValues.category &&
            renderImportedValueItem(
              'category',
              'Category',
              importedValues.category,
              importedValues.validation.categoryValid
            )}
          {importedValues.productionDate &&
            renderImportedValueItem(
              'productionDate',
              'Production date',
              importedValues.productionDate,
              importedValues.validation.productionDateValid
            )}
          {importedValues.vin &&
            renderImportedValueItem('vin', 'VIN', importedValues.vin)}

          <Button
            onClick={onImport}
            disabled={!importedValues.validation.allValid || importingValues}
            variant="contained"
            size="large"
            color="primary"
            className={styles.importButton}
          >
            Import
          </Button>
        </>
      )}

      <Dialog
        open={importingValues}
        aria-labelledby="importing-values-dialog"
        aria-describedby="importing-values-dialog"
      >
        <DialogTitle id="importing-values-dialog-title">
          Importing values
        </DialogTitle>
        <DialogContent>
          <div className={styles.importSpinner}>
            <CircularProgress />
          </div>
        </DialogContent>
      </Dialog>

      {importFailed && (
        <AlertDialog
          title="Failure"
          message="Failed to import values"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          onDismis={() => setImportFailed(false)}
        />
      )}
    </div>
  );
};

export default withOktaAuth(ImportValues);
